// main imports
import * as React from "react";

// plugin imports
import { Parallax } from "react-scroll-parallax";
import { Link } from "gatsby-plugin-react-i18next";

// style imports
import "./index.scss";

// svg imports

// components imports
import Layout from "../components/Layout/Layout.js";
import Seo from "../components/Seo/Seo.js";

// page

const NotFoundPage = () => {
	const parallaxSettings = {
		speed: 20,
		easing: "easeInOut",
		className: "parallaxSection",
		onEnter: (element) => element.style,
	};

	return (
		<Layout>
			<Seo title="404" />
			<div className="bodyContainer">
				<section>
					<Parallax {...parallaxSettings}>
						<h1>
							bossa no va.
							<br />
							error 404.
						</h1>

						<p style={{ textAlign: "right" }}>
							Sorry, we couldn't find the page you're looking for.<br></br>
							Disculpa, no podemos encontrar la página que estás buscando.
							<br></br>
							<br></br>
							<Link to="/">Go back home</Link>.
						</p>
					</Parallax>
				</section>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
